<template>
	<div class="pie_echarts">
		<div ref="echarts"></div>
	</div>
</template>

<script>
	export default {
		name: "pieEcharts",
		props: {
			// 配置数据
			piedata: {
				type: Array,
			},
			dataPie: {
				type: Array,
			},
			index: {
				type: Number,
			},
		},
		// 父组件传入更新数据时，重新渲染图表展示新数据
		watch: {
			piedata: {
				// 数据更新后更新视图，初次绑定时不执行方法
				handler: function() {
					this.initEcharts();
				},
				deep: true,
				immediate: false,
			},
		},
		methods: {
			initEcharts() {
				// 由于全局注册了echarts，在这边可以直接调用$echarts来初始化
				var myChart = this.$echarts.init(this.$refs.echarts);
				let dataPie = this.dataPie[this.index];
				let sum = 0;
				for (let i in dataPie) {
					sum = sum + parseFloat(Math.abs(dataPie[i].value));
				}
				//let that = this
				var option4 = {
					title: [{
						text: null == this.dataPie[this.index] ? "" : this.piedata[this.index].name, // 主标题
						// x: "52%",
						x: "19%",
						y: "10%"
					}, ],

					tooltip: {
						trigger: "item",
						formatter(e) {
							let item = e.data.name.split(",");
							return item[0] + ' :   ' + item[1]
						},
					},
					legend: [{
						orient: "scroll",
						left: "50%", // 表格的位置
						// right: "0",
						y: "center",
						formatter(e) {
							let item = e.split(",");
							if (undefined == item[1]) {
								return "-暂无数据-";
							} else {
								const percent = (parseFloat(Math.abs(item[1])) / parseFloat(sum)) * 100;
								return (item[0] + "    " + item[1] + "    " + percent.toFixed(2) + "%");
							}
						},
					}, ],
					series: [{
						name: null == this.dataPie[this.index] ? "" : this.piedata[this.index].name,
						type: "pie",
						radius: ["40%", "25%"],
						center: ["25%", "50%"], // x轴、y轴的位置
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: "center",
						},
						emphasis: {
							label: {
								show: false,
								fontSize: "40",
								fontWeight: "bold",
							},
						},
						labelLine: {
							show: false,
						},
						data: this.dataPie[this.index],
					}, ],
				};
				myChart.setOption(option4);
			},
		},
		mounted() {
			this.$nextTick(() => {
				setTimeout(() => {
					//弹框嵌套图表-请求数据有延迟
					this.initEcharts();
				}, 100);
			});
		},
	};
</script>

<style lang="scss" scoped>
	/* 保证可以撑满父组件用来包裹本组件的盒子 */
	.pie_echarts {
		width: 100%;
		height: 100%;

		div {
			width: 100%;
			height: 100%;
		}
	}
</style>
